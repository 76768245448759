import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { Token } from '../../models';
import { useConfig, useSession } from '../index';

const skipRefreshUrls = [`/refresh`, `/users/token/`];

const useAxios = (serviceUrl: string, withCredentials: boolean = false) => {
  const {
    IDPToken,
    orionToken,
    session,
    logout,
    refreshIDPToken,
    refreshSessionTimer,
    transmitToken
  } = useSession();
  const { investConfig } = useConfig();
  const defaultConfig: AxiosRequestConfig = {
    baseURL: investConfig.baseUrl,
    withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const investClient: AxiosInstance = axios.create(defaultConfig);

  investClient.interceptors.request.use(
    async (request) => {
      let newToken: Token = new Token();
      if (new Date(IDPToken.expiresOn) < new Date()) {
        newToken = (await refreshIDPToken()) as Token;
      }
      request.headers.Authorization = `${IDPToken.tokenType} ${
        newToken.token || IDPToken.token
      }`;
      
      // It would be better to add a param when instatiating a useAxios instance for which
      // token to use instead of passing in a header but Plaid needs to go out
      if (request.headers.useTransmitToken) {
        request.headers.Authorization = `${IDPToken.tokenType} ${
          transmitToken.access_token
        }`;

        delete request.headers.useTransmitToken;
      }
      
      return request;
    },
    (error) => {
      console.log('Rejecting request');
      return Promise.reject(error);
    }
  );

  investClient.interceptors.response.use(
    (response: AxiosResponse) => {
      const url = response.config.url?.toLowerCase();
      if (!skipRefreshUrls.some((route) => url?.includes(route))) {
        refreshSessionTimer();
      }
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      } else if (error.response.state === '401 Unauthorized: [no body]') {
        console.log('Expired Orion Token, get a new one');
      } else {
        console.error(`${error.response.config.url}`, error);
      }
      return Promise.reject(error.response);
    }
  );

  /**
   * Instance to be used when you are calling invest api and need to attach
   * the IDP Token
   * @param method get, post, put, delete
   * @param url url to attach to the base invest api url
   * @param headersConfig any headers that need to be configured
   * @param includeOrion if the headers should include orion token and external-client-id
   * @returns AxiosInstance to be called
   */
  function investInstance<T = any>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    headersConfig: AxiosRequestConfig = {},
    includeOrion: boolean = false
  ): AxiosPromise<T> {
    investClient.defaults.method = method;
    if (includeOrion) {
      investClient.defaults.headers['orion-token'] = orionToken.token;
      investClient.defaults.headers['external-client-id'] =
        session.householdIds.allyHouseholdId;
    }
    return investClient(`${serviceUrl}${url}`, headersConfig);
  }

  return { investInstance };
};

export default useAxios;
